export var MetaDataType;
(function (MetaDataType) {
    MetaDataType["COPIES"] = "COPIES";
    MetaDataType["ORIENTATION"] = "ORIENTATION";
    MetaDataType["STANDARD_SIZES"] = "STANDARD_SIZES";
    MetaDataType["SUBSTRATES"] = "SUBSTRATES";
})(MetaDataType || (MetaDataType = {}));
var FLAT_METADATA_TYPE_MAP = [
    { type: MetaDataType.COPIES },
    { type: MetaDataType.ORIENTATION },
    { type: MetaDataType.STANDARD_SIZES, foldingAvailable: false }
];
var FOLDED_METADATA_TYPE_MAP = [
    { type: MetaDataType.COPIES },
    { type: MetaDataType.ORIENTATION },
    { type: MetaDataType.STANDARD_SIZES, foldingAvailable: true }
];
var BOUND_METADATA_TYPE_MAP = [
    { type: MetaDataType.COPIES },
    { type: MetaDataType.ORIENTATION },
    { type: MetaDataType.STANDARD_SIZES, foldingAvailable: false }
];
export var ProductMetaDataTypeMap = {
    BROCHURES: BOUND_METADATA_TYPE_MAP,
    FLYERS: FLAT_METADATA_TYPE_MAP,
    BOOK: BOUND_METADATA_TYPE_MAP,
    BUSINESS_CARDS: FOLDED_METADATA_TYPE_MAP,
    CANVAS: FLAT_METADATA_TYPE_MAP,
    COMPLIMENT_SLIPS: FLAT_METADATA_TYPE_MAP,
    DESK_CALENDARS: BOUND_METADATA_TYPE_MAP,
    FOLDED_LEAFLETS: FOLDED_METADATA_TYPE_MAP,
    GREETING_CARDS: FOLDED_METADATA_TYPE_MAP,
    LAYFLAT_BOOKS: BOUND_METADATA_TYPE_MAP,
    LETTERHEADS: FLAT_METADATA_TYPE_MAP,
    NOTE_BOOKS: BOUND_METADATA_TYPE_MAP,
    POSTCARDS: FLAT_METADATA_TYPE_MAP,
    POSTERS: FLAT_METADATA_TYPE_MAP,
    VR_DESK_CALENDARS: BOUND_METADATA_TYPE_MAP,
    VR_WALL_CALENDARS: BOUND_METADATA_TYPE_MAP,
    WALL_CALENDARS: BOUND_METADATA_TYPE_MAP,
    // We Don't Actually Use These Products
    ENVELOPES: [],
    FOLDERS: [],
    FA_EXTREMELY_ADHESIVE_STICKERS: [],
    FA_FOOD_STICKERS: [],
    FA_GEL_STICKERS: [],
    FA_HEAT_RESISTANT_STICKERS: [],
    FA_INDOOR_STICKERS: [],
    FA_LUMINOUS_STICKERS: [],
    FA_NEON_STICKERS: [],
    FA_OUTDOOR_STICKERS: [],
    FA_REFLECTIVE_STICKERS: [],
    FA_REMOVABLE_STICKERS: [],
    FA_STICKER_ROLL: [],
    FA_STICKER_SHEET: [],
    FA_VEGAN_INDOOR_STICKERS: [],
    FA_VEGAN_OUTDOOR_STICKERS: [],
    PHOTO_BOOKS: [],
    TRADITIONAL_BOOKS: [],
};
export var UnitType;
(function (UnitType) {
    UnitType["MILLIMETERS"] = "MILLIMETERS";
    UnitType["INCHES"] = "INCHES";
})(UnitType || (UnitType = {}));
