var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Button, Table, Form as BsForm, Toast, Alert } from 'react-bootstrap';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import ConfirmationModal from './ConfirmationModal';
import { ComponentType } from "@mixam-platform/mixam-types";
var SupportedSizeRow = function (_a) {
    var pressSheet = _a.pressSheet, supportedSize = _a.supportedSize, configuration = _a.configuration, doGetConfiguration = _a.doGetConfiguration;
    var _b = useState(false), showDeleteModal = _b[0], setShowDeleteModal = _b[1];
    var doDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var supportedSizes, url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    supportedSizes = pressSheet.supportedSizes.filter(function (size) {
                        return !(size.standardSize === supportedSize.standardSize && size.componentType === supportedSize.componentType);
                    });
                    url = "/api/admin/tco/configurations/".concat(configuration.machineName, "/press-sheets/").concat(pressSheet.id);
                    return [4 /*yield*/, axios.put(url, __assign(__assign({}, pressSheet), { supportedSizes: supportedSizes }))
                            .then(function () {
                            doGetConfiguration();
                            setShowDeleteModal(false);
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'success', body: 'Item Size Removed', interval: 15000 } } }));
                        }).catch(function (error) {
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'error', body: "Failed to Remove Item Size: ".concat(error.response.data.error), interval: 30000 } } }));
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(ConfirmationModal, { show: showDeleteModal, handleClose: function () { return setShowDeleteModal(false); }, handleSubmit: doDelete, closeButtonText: "Close", content: "Are you sure you want to delete this size?", saveButtonText: "Delete", heading: "Delete Press" }),
        React.createElement("tr", null,
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "p-1" }, supportedSize.dinSize ? 'A' + supportedSize.dinSize : supportedSize.standardSize)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "p-1" }, supportedSize.componentType)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "p-1" }, "".concat(pressSheet.width, " x ").concat(pressSheet.height))),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "p-1" }, supportedSize.pagesPerSection)),
            React.createElement("td", null,
                React.createElement(Button, { variant: "danger", type: "button", onClick: function () { return setShowDeleteModal(true); } },
                    React.createElement("i", { className: "bi bi-trash" }))))));
};
export function ItemSizes(_a) {
    var _this = this;
    var configuration = _a.configuration, doGetConfiguration = _a.doGetConfiguration, awesomeQueryBuilder = _a.awesomeQueryBuilder;
    var _b = useState(false), showModal = _b[0], setShowModal = _b[1];
    var _c = useState(undefined), newSupportedSize = _c[0], setNewSupportedSize = _c[1];
    var _d = useState(false), showDuplicateIsDetectedToast = _d[0], setShowDuplicateIsDetectedToast = _d[1];
    var handleClose = function () {
        setShowModal(false);
    };
    var handleShow = function () { return setShowModal(true); };
    useEffect(function () {
        configuration && awesomeQueryBuilder &&
            setNewSupportedSize({
                pressSheetId: '',
                data: {
                    standardSize: undefined,
                    componentType: undefined,
                    dinSize: undefined,
                    pagesPerSection: 16,
                }
            });
    }, [configuration, awesomeQueryBuilder]);
    var handleSubmitSupportedSize = function () { return __awaiter(_this, void 0, void 0, function () {
        var pressSheet, res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    pressSheet = configuration.pressSheets.find(function (sheet) { return sheet.id == newSupportedSize.pressSheetId; });
                    pressSheet.supportedSizes.push(newSupportedSize.data);
                    return [4 /*yield*/, axios.put("/api/admin/tco/configurations/".concat(configuration.machineName, "/press-sheets/").concat(pressSheet.id), pressSheet)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
                case 2:
                    e_1 = _a.sent();
                    console.log('error submitting');
                    throw e_1;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var doSaveSupportedSize = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleSubmitSupportedSize()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, doGetConfiguration()];
                case 2:
                    _a.sent();
                    setShowModal(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function (event) {
        event.preventDefault();
        var form = event.currentTarget;
        if (form.checkValidity()) {
            // once basic form validation has been checked, also client-check for duplicates
            // these values aren't so simple to check one by one, so an array existingValues is created to compare with
            var hasDuplicates = configuration.pressSheets.map(function (sheet) {
                return sheet.supportedSizes.map(function (supportedSize) {
                    return {
                        pressSheetId: sheet.id,
                        size: supportedSize.dinSize ? supportedSize.dinSize : supportedSize.standardSize,
                        componentType: supportedSize.componentType
                    };
                });
            }).flatMap(function (x) { return x; }).some(function (existingValue) {
                return existingValue.pressSheetId === newSupportedSize.pressSheetId &&
                    existingValue.componentType === newSupportedSize.data.componentType &&
                    (existingValue.size == newSupportedSize.data.dinSize ||
                        existingValue.size == newSupportedSize.data.standardSize);
            });
            if (hasDuplicates) {
                setShowDuplicateIsDetectedToast(true);
                return;
            }
            else {
                // event.stopPropagation();
                doSaveSupportedSize();
            }
        }
    };
    var dinSizes = [1, 2, 3, 4, 5, 6, 7];
    var handleSetStandardOrDinSize = function (e) {
        dinSizes.includes(Number(e.target.value)) ?
            setNewSupportedSize(function (prevState) { return (__assign(__assign({}, prevState), { data: __assign(__assign({}, prevState.data), { standardSize: undefined, dinSize: Number(e.target.value) }) })); }) :
            setNewSupportedSize(function (prevState) { return (__assign(__assign({}, prevState), { data: __assign(__assign({}, prevState.data), { standardSize: e.target.value, dinSize: undefined }) })); });
    };
    return (React.createElement("div", { className: "d-flex flex-column gap-4" },
        React.createElement("div", { className: "d-flex flex-column" },
            React.createElement("h4", null, "Item Sizes"),
            React.createElement(BsForm, null,
                React.createElement("div", { className: "d-flex flex-column" },
                    React.createElement(Table, { striped: true, bordered: true, hover: true },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", null, "Size"),
                                React.createElement("th", null, "Component"),
                                React.createElement("th", null, "Sheet"),
                                React.createElement("th", null, "Sides"),
                                React.createElement("th", null))),
                        React.createElement("tbody", null, configuration && configuration.pressSheets.map(function (sheet) {
                            return sheet.supportedSizes && sheet.supportedSizes.map(function (size) { return (React.createElement(SupportedSizeRow, { key: "".concat(size.dinSize ? size.dinSize : size.standardSize, "-").concat(size.componentType), pressSheet: sheet, supportedSize: size, configuration: configuration, doGetConfiguration: doGetConfiguration })); });
                        }))),
                    (!configuration || !configuration.pressSheets) && React.createElement("p", null, "No Data"),
                    React.createElement("div", { className: "d-flex flex-row gap-2 justify-content-end" },
                        React.createElement(Button, { className: "align-self-end", type: "button", onClick: handleShow }, "+ Add Item Size")))),
            showModal &&
                React.createElement(Modal, { show: showModal, onHide: handleClose, size: "lg" },
                    React.createElement(BsForm, { onSubmit: handleSubmit },
                        React.createElement(Modal.Header, { closeButton: true },
                            React.createElement(Modal.Title, null, "Add Item")),
                        React.createElement(Modal.Body, null,
                            React.createElement(Table, null,
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", null, "Size"),
                                        React.createElement("th", null, "Component"),
                                        React.createElement("th", null, "Sheet"),
                                        React.createElement("th", null, "Sides"),
                                        React.createElement("th", null))),
                                React.createElement("tbody", null,
                                    React.createElement("tr", null,
                                        React.createElement("td", null,
                                            React.createElement(BsForm.Select, { required: true, name: "standardSize", onChange: handleSetStandardOrDinSize },
                                                React.createElement("option", null, "Please Select"),
                                                dinSizes.map(function (size) {
                                                    return (React.createElement("option", { key: size, value: size },
                                                        "A",
                                                        size));
                                                }),
                                                awesomeQueryBuilder.standardSizes.map(function (type) {
                                                    return type !== 'NONE' ? (React.createElement("option", { key: type, value: type }, type)) : React.createElement(React.Fragment, null);
                                                }))),
                                        React.createElement("td", null,
                                            React.createElement(BsForm.Select, { required: true, name: "componentType", onChange: function (e) { return setNewSupportedSize(function (prevState) { return (__assign(__assign({}, prevState), { data: __assign(__assign({}, prevState.data), { componentType: ComponentType[e.target.value] }) })); }); } },
                                                React.createElement("option", null, "Please Select"),
                                                awesomeQueryBuilder.componentTypes.map(function (type) {
                                                    return (React.createElement("option", { key: type, value: type }, type));
                                                }))),
                                        React.createElement("td", null,
                                            React.createElement(BsForm.Select, { required: true, name: "pressSheetId", id: "pressSheetId", onChange: function (e) { return setNewSupportedSize(function (prevState) { return (__assign(__assign({}, prevState), { pressSheetId: e.target.value })); }); } },
                                                React.createElement("option", null, "Please Select"),
                                                configuration.pressSheets.map(function (sheet) {
                                                    return (React.createElement("option", { key: sheet.id, value: sheet.id }, sheet.width + ' x ' + sheet.height));
                                                }))),
                                        React.createElement("td", null,
                                            React.createElement(BsForm.Select, { required: true, name: "pagesPerSection", defaultValue: "16", onChange: function (e) { return setNewSupportedSize(function (prevState) { return (__assign(__assign({}, prevState), { data: __assign(__assign({}, prevState.data), { pagesPerSection: Number(e.target.value) }) })); }); } },
                                                React.createElement("option", { value: "2" }, "2"),
                                                React.createElement("option", { value: "4" }, "4"),
                                                React.createElement("option", { value: "8" }, "8"),
                                                React.createElement("option", { value: "16" }, "16"),
                                                React.createElement("option", { value: "32" }, "32")))))),
                            React.createElement("div", { className: "d-flex w-100 justify-content-center" },
                                React.createElement(Toast, { onClose: function () { return setShowDuplicateIsDetectedToast(false); }, show: showDuplicateIsDetectedToast, className: "w-100", autohide: true, delay: 3000 },
                                    React.createElement(Alert, { variant: "primary", className: "mb-0" },
                                        React.createElement("p", null, "This entry already exists, please create a unique entry and try again."))))),
                        React.createElement(Modal.Footer, null,
                            React.createElement(Button, { variant: "secondary", onClick: handleClose }, "Close"),
                            React.createElement(Button, { variant: "primary", type: "submit" }, "Save")))))));
}
