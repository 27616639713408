import {initializePrivate} from "./page-private";

// Mixam Flight Components
import UserObligo from "./appes6/component_ui/order/user-obligo";
import ResellerRequestList from './appes6/component_ui/admin/reseller/admin-reseller-requests';
import MemberRevShareManager from "./appes6/component_ui/member/revshare/revenue-share-manage";
import AdminTaxExemptionCertificates from "./appes6/component_ui/admin/members/admin-tax-exemption-certificates";
import adminMembersActivity from "./appes6/component_ui/admin/members/members-activity-manager";
import adminRealtime from "./appes6/component_ui/admin/analytics/realtime/admin-realtime";
import adminItems from "./appes6/component_ui/admin/admin-items";
import adminAnalytics from "./appes6/component_ui/admin/analytics/reports/admin-analytics";
import adminDashboard from "./appes6/component_ui/admin/admin-dashboard";
import catalogManager from "./appes6/component_ui/admin/admin-catalog";
import AdminOrdersManager from "./appes6/component_ui/admin/admin-orders-manager";
import AdminItemsManager from "./appes6/component_ui/admin/admin-items-manager";
import AdminPaymentsManager from "./appes6/component_ui/admin/payments/admin-payments-manager";
import AdminArticlesManager from "./appes6/component_ui/admin/article/admin-articles-manager";
import AdminArticleHistory from "./appes6/component_ui/admin/article/admin-article-history";
import AdminCreditManager from "./appes6/component_ui/admin/admin-credit-manager";
import AdminTasksManager from "./appes6/component_ui/admin/admin-tasks-manager";
import AdminArticlesSummary from "./appes6/component_ui/admin/article/article-summary";
import Filter from "./appes6/component_ui/table/filter";
import ItemDispatcher from "./appes6/component_ui/provider/item-dispatcher";
import SantaStats from "./appes6/component_ui/admin/admin-santa-stats";
import MixamLocalUser from "./appes6/component_ui/user/user";
import State from "./appes6/component_ui/state";
import OrderTimeliner from "./appes6/component_ui/order/order-timeline";
import AdminReload from "./appes6/component_ui/admin/reloader";
import GoogleSheetApi from "./appes6/component_ui/admin/google-sheet-api";
import AdminAccountsPayable from "./appes6/component_ui/admin/admin-accounts-payable";
import AdminJournalManager from "./appes6/component_ui/admin/admin-journal";
import CustomerGroups from "./appes6/component_ui/admin/groups/customer-groups";
import MemberGroupsManager from "./appes6/component_ui/member/member-groups-manager";
import MergeProcess from "./appes6/component_ui/admin/admin-merge-process";
import LiveUploads from "./appes6/component_ui/admin/admin-live-uploads";
import LiveLog from "./appes6/component_ui/livelog/live-log";
import SampleRequetList from "./appes6/component_ui/admin/admin-sample-requests";
import SuppliersDataTable from "./appes6/component_ui/admin/catalog/suppliers-datatable";
import PageRangesDataTable from "./appes6/component_ui/admin/catalog/page-ranges-datatable";
import CreatePageRanges from "./appes6/component_ui/admin/catalog/create-page-ranges";
import CollectionCentresDataTable from "./appes6/component_ui/admin/catalog/collection-centres-datatable";
import ProductionCentresDataTable from "./appes6/component_ui/admin/catalog/production-centres-datatable";
import PaperTypesDataTable from "./appes6/component_ui/admin/catalog/paper-types-datatable";
import ShopDataTable from "./appes6/component_ui/admin/shop/shop-datatable";
import ShopEditor from "./appes6/component_ui/admin/shop/shop-editor";
import InvoicesList from "./appes6/component_ui/admin/invoices/admin-invoices";
import CxmlManager from "./appes6/component_ui/admin/admin-cxmlpost-manager";
import AdminShareManager from "./appes6/component_ui/admin/analytics/shares/admin-shares-manager";
import AdminShareHero from "./appes6/component_ui/admin/analytics/shares/admin-share-hero";
import AnalyticsYearView from "./appes6/component_ui/admin/analytics/year-view/year-view";
import UploadInvoiceManager from "./appes6/component_ui/admin/upload-invoice-manager";
import SiteflowApiJobManager from "./appes6/component_ui/admin/admin-siteflow-manager";
import AdminResellerActivity from "./appes6/component_ui/admin/reseller/reseller-activity-manager";
import MemberNewsletter from "./appes6/component_ui/admin/members/admin-newsletter-manager";
import OrderLocator from "./appes6/component_ui/admin/order-locator";
import MemberLocator from "./appes6/component_ui/admin/members/admin-member-locator";
import ReviewsTrustpilotManager from "./appes6/component_ui/admin/reviews/admin-reviews-trustpilot";
import MemberReviewInvite from "./appes6/component_ui/admin/member-review-invite";
import TradeprintApiJobManager from "./appes6/component_ui/admin/tradeprint/admin-tradeprint-post-manager";
import TaylorsJobmanager from "./appes6/component_ui/admin/taylors/admin-taylors-job-manager";
import PrintedJobManager from "./appes6/component_ui/admin/printed/admin-printed-job-manager";
import PrintessWebhookManager from "./appes6/component_ui/admin/printess/admin-printess-webhook-manager";
import ShopCalendarPreview from "./appes6/component_ui/admin/shop-calendar";
import YearSelect from "./appes6/component_ui/admin/year-select";
import PageNavigate from "./appes6/component_ui/page-navigate";
import OneTimeDialog from "./appes6/component_ui/one-time-dialog";
import AnalogClock from "./appes6/component_ui/analog-clock";
import ManualDeliveryManager from "./appes6/component_ui/order/manual-delivery-manager";
import CutoffZoneManager from "./appes6/component_ui/admin/cutoff-zone-manager";
import OrdersExporter from "./appes6/component_ui/admin/order-exporter";
import InvoiceExporter from "./appes6/component_ui/admin/invoices/invoice-exporter";
import WorldShopPayments from "./appes6/component_ui/admin/world-shop-payments";
import OdeonList from "./appes6/component_ui/admin/analytics/video/admin-odeon-list";
import DisputesList from "./appes6/component_ui/admin/admin-disputes";
import FlyeralarmJobsManager from "./appes6/component_ui/admin/flyeralarm/admin-flyeralarm-jobs-manager";
import PhotoUploadManager from "./appes6/component_ui/member/photo-upload-manager";
import MachinesDataTable from "./appes6/component_ui/admin/catalog/machines-datatable";
import MachineEditor from "./appes6/component_ui/admin/catalog/machine-editor";
import MachinePrintRunEditor from "./appes6/component_ui/admin/catalog/machine-print-run-editor";
import MachineMarkupEditor from "./appes6/component_ui/admin/catalog/machine-overheads-editor";
import SupplierPageRangeEditor from "./appes6/component_ui/admin/catalog/supplier-page-ranges-editor";
import MachineAllowedProductsEditor from "./appes6/component_ui/admin/catalog/machine-allowed-products-editor";
import MachinePriceListsEditor from "./appes6/component_ui/admin/catalog/machine-price-list-editor";
import PaperTypeWeightEditor from "./appes6/component_ui/admin/catalog/paper-type-weight-editor";
import SupplierEditor from "./appes6/component_ui/admin/catalog/supplier-editor";
import SupplierMachineEditor from "./appes6/component_ui/admin/catalog/supplier-machine-editor";
import SupplierDelayEditor from "./appes6/component_ui/admin/catalog/supplier-delay-editor";
import PlaygroundSpecs from "./appes6/component_ui/playground/playground-specs";
import CkEditor from "./appes6/component_ui/admin/admin-ckeditor";
import BlogCategoryDataTable from "./appes6/component_ui/admin/blog/blog-category-datatable";
import BlogArticleDataTable from "./appes6/component_ui/admin/blog/blog-article-datatable";
import AdminCustomerCredits from "./appes6/component_ui/admin/members/admin-customer-credits";
import ProductCategoryDataTable from "./appes6/component_ui/admin/product/product-category-datatable";
import ProductPageDataTable from "./appes6/component_ui/admin/product/product-page-datatable";
import ProductMetaDataDataTable from "./appes6/component_ui/admin/product/product-metadata-datatable";
import ProductPriceCalculatorEditor from "./appes6/component_ui/admin/product/product-price-calculator-editor";
import KnowledgeBaseArticleDataTable from "./appes6/component_ui/admin/knowledge-base/knowledge-base-article-datatable";
import KnowledgeBaseCategoryDataTable from "./appes6/component_ui/admin/knowledge-base/knowledge-base-category-datatable";
import BluetreeJobsManager from "./appes6/component_ui/admin/bluetree/admin-bluetree-jobs-manager";
import DocumationJobsManager from "./appes6/component_ui/admin/documation/admin-documation-jobs-manager";
import AdminHeader from "./appes6/component_ui/admin/admin-header";
import AdminFormValidator from './appes6/component_ui/admin/forms/admin-forms-validator';
import AdminShopSwitcher from "./appes6/component_ui/admin/admin-shop-switcher";
import ProductEditor from "./appes6/component_ui/admin/product/product-editor";
import ProductPageMediaEditor from "./appes6/component_ui/admin/product/product-media-editor";
import FormAnimSubmitter from "./appes6/component_ui/form-anim-submiter";
import RecruitCandidate from "./appes6/component_ui/recruitment/recruit-candidate";
import RecruitTester from "./appes6/component_ui/recruitment/recruit-tester";
import AdminPublicationsManager from "./appes6/component_ui/admin/publications/admin-publications";
import AdminActivePublicationsManager from "./appes6/component_ui/admin/publications/admin-active-publications";
import AdminPendingPublicationsManager from "./appes6/component_ui/admin/publications/admin-pending-publications";
import CmsPageDataTable from "./appes6/component_ui/admin/cms/cms-page-datatable";
import CmsPageEditor from "./appes6/component_ui/admin/cms/cms-page-editor";
import CmsGroupDataTable from "./appes6/component_ui/admin/cms/cms-group-datatable";
import AdminCmsGroupManager from "./appes6/component_ui/admin/cms/cms-group-manager";
import AdminFaqManager from "./appes6/component_ui/admin/faq/admin-faq-manager";
import AdminTooltipManager from "./appes6/component_ui/admin/tooltip/admin-tooltip-manager";
import AdminMembersExporter from "./appes6/component_ui/admin/members/admin-member-exporter";
import ShopNexusTable from "./appes6/component_ui/admin/shop/nexus.tsx";
import ShopCalendarTable from "./appes6/component_ui/admin/shop/calendar.tsx";
import AdminPodFulfilmentQueue from "./appes6/component_ui/admin/pod/admin-pod-fulfilment-queue";
import AdminPublisherSales from "./appes6/component_ui/admin/pod/admin-publisher-sales";
import AdminPublisherItemSales from "./appes6/component_ui/admin/pod/admin-publisher-item-sales";
import AdminFulfillmentReport from "./appes6/component_ui/admin/analytics/fulfillment/admin-fulfillment-report";
import AdminParticipationRuleBuilder from "./appes6/component_ui/admin/particicipation/admin-participation-rule-builder";
import ParticipationRulesDataTable from "./appes6/component_ui/admin/catalog/participation-rules-datatable";
import AdminDeliveryManager from "./appes6/component_ui/admin/admin-delivery-manager";
import AdminPodApproval from "./appes6/component_ui/admin/pod/admin-pod-approval";
import OrderItemChargeItemsManager from "./appes6/component_ui/order/charge-items-manager";
import DiscountCodesDataTable from "./appes6/component_ui/admin/discountcodes/discount-codes-datatable";
import AdminDiscountCodeEditor from "./appes6/component_ui/admin/discountcodes/admin-discount-code-editor";
import AdminDiscountCodesReport from "./appes6/component_ui/admin/analytics/discountcodes/admin-discount-codes-report";
import HomepageSettingsDataTable from "./appes6/component_ui/admin/homepage/homepage-settings-datatable";
import AdminHomepagePriceCalcultorManager from "./appes6/component_ui/admin/homepage/homepage-price-calculator-manager";
import AdminHomepageFeaturedProductManager from "./appes6/component_ui/admin/homepage/homepage-featured-product-manager";
import FeatureRequestsDataTable from "./appes6/component_ui/admin/analytics/featurerequests/admin-feature-requests-datatable";
import FaqPageDataTable from "./appes6/component_ui/admin/cms/cms-faq-page-datatable";
import FaqCategoryDataTable from "./appes6/component_ui/admin/cms/cms-faq-category-datatable";
import ResellerFaqDataTable from "./appes6/component_ui/admin/cms/cms-reseller-faq-datatable";
import TinyUrlDataTable from "./appes6/component_ui/admin/tinyurl/tinyurl-datatable";
import AdminTinyUrlEditor from "./appes6/component_ui/admin/tinyurl/admin-tinyurl-editor";
import AdminDiversionReport from "./appes6/component_ui/admin/analytics/supplier-diversions/admin-diversion-report";
import AdminSmsNotifications from "./appes6/component_ui/admin/customers/sms-notifications/admin-sms-notifications";
import CreatorRegistrationDatatable from "./appes6/component_ui/admin/creator/admin-creator-registration-datatable";
import MxjdfApiJobManager from "./appes6/component_ui/admin/mxjdf/admin-mxjdf-api-job-manager";
import RpiApiJobManager from "./appes6/component_ui/admin/rpi/admin-rpi-api-job-manager";
import SaxoPrintApiJobManager from "./appes6/component_ui/admin/saxoprint/admin-saxoprint-api-job-manager";
import CustomerManager from "./appes6/component_ui/admin/members/admin-customer-manager";
import AdminTcoConfiguration from "./appes6/component_ui/admin/tco/admin-tco-configuration";
import ApiRequestLog from "./appes6/component_ui/admin/api-log/admin-api-request-log";
import ApiWebhookLog from "./appes6/component_ui/admin/api-webhooks/admin-api-webhook-log";
import ShopifyWebhookManager from "./appes6/component_ui/admin/shopify/shopify-webhook-event";
import ShopifyActiveProducts from "./appes6/component_ui/admin/shopify/active-products";
import AdminAccountsPayableDataImporter from "./appes6/component_ui/admin/admin-accounts-payable-data-importer";
import RedirectsDataTable from "./appes6/component_ui/admin/redirects/redirects-datatable";
import SelfPublishingConfigDataTable from "./appes6/component_ui/admin/configuration/configuration-publishing-datatable";
import PrintboxOrdersDataTable from "./appes6/component_ui/admin/printbox/printbox-orders-datatable";
import AdminProductMetaDataEditor from "./appes6/component_ui/admin/product/admin-product-metadata-editor";

function initializeAdmin(callback = () => {}) {

    initializePrivate(() => {
        // Attach Flight components...
        State.attachTo(document);
        UserObligo.attachTo('[data-type="user-obligo"]');                           // Order Manager (set credit limit)
        PlaygroundSpecs.attachTo('[data-type="playground-specs"]');                 // Playground (Admin)
        OrderTimeliner.attachTo('[data-type="timeline-view"]');
        AdminTaxExemptionCertificates.attachTo('[data-type="admin-tax-exemption-certificates"]');
        adminMembersActivity.attachTo('[data-type="admin-members-activity"]');
        adminAnalytics.attachTo("#adminReports #main");
        adminRealtime.attachTo('[data-type="admin-realtime"]');
        catalogManager.attachTo("#catalogManager");
        AdminOrdersManager.attachTo('[data-type="admin-orders"]');
        AdminItemsManager.attachTo('[data-type="admin-items"]');
        AdminPaymentsManager.attachTo('[data-type="admin-payments"]');
        AdminArticlesManager.attachTo('[data-type="admin-articles"]');
        AdminArticleHistory.attachTo('[data-type="admin-article-history"]');
        AdminCreditManager.attachTo('[data-type="admin-credit"]');
        AdminDeliveryManager.attachTo('[data-type="admin-delivery-reconciliation"]');
        AdminTasksManager.attachTo('[data-type="admin-tasks"]');
        AdminArticlesSummary.attachTo('[data-type="article-summary"]');
        AdminHeader.attachTo('[data-type="admin-header"]');
        Filter.attachTo('section.filter');
        SantaStats.attachTo('[data-type="santa-stats"]');
        adminItems.attachTo('[data-type="items-search"]');
        ItemDispatcher.attachTo('[data-type="item-dispatcher"]');
        MemberRevShareManager.attachTo('[data-type="member-revshare"]');
        MixamLocalUser.attachTo(document);
        AdminReload.attachTo('[data-type="admin-reload"]');
        GoogleSheetApi.attachTo('[data-type="google-sheets-api"]');
        AdminAccountsPayable.attachTo('[data-type="admin-accounts-payable"]');
        AdminJournalManager.attachTo('[data-type="admin-jornal"]');
        CustomerGroups.attachTo('[data-type="admin-customer-groups"]');
        MemberGroupsManager.attachTo('[data-type="member-groups-manager"]');
        MergeProcess.attachTo('[data-type="admin-merge-process"]');
        LiveUploads.attachTo('[data-type="upload-monitor"]');
        LiveLog.attachTo('[data-type="live-log"]');
        SampleRequetList.attachTo('[data-type="admin-sample-requests"]');
        SuppliersDataTable.attachTo('[data-type="admin-suppliers-datatable"]');
        PageRangesDataTable.attachTo('[data-type="admin-page-ranges-datatable"]');
        CreatePageRanges.attachTo('[data-type="create-page-ranges-datatable"]');
        CollectionCentresDataTable.attachTo('[data-type="admin-collection-centres-datatable"]');
        PaperTypesDataTable.attachTo('[data-type="admin-paper-types-datatable"]');
        ProductionCentresDataTable.attachTo('[data-type="admin-production-centres-datatable"]');
        ShopDataTable.attachTo('[data-type="admin-shop-datatable"]');
        ShopEditor.attachTo('[data-type="admin-shop-editor"]');
        InvoicesList.attachTo('[data-type="admin-invoices"]');
        CxmlManager.attachTo('[data-type="admin-cxmlpost-requests"]');
        OrdersExporter.attachTo('[data-type="order-exporter"]');
        InvoiceExporter.attachTo('[data-type="invoice-exporter"]');
        AdminShareManager.attachTo('[data-type="admin-shares"]');
        AdminShareHero.attachTo('[data-type="share-hero"]');
        AnalyticsYearView.attachTo('[data-type="admin-analytics-year"]');
        UploadInvoiceManager.attachTo('[data-type="upload-invoice"]');
        ResellerRequestList.attachTo('[data-type="admin-reseller-request"]');
        SiteflowApiJobManager.attachTo('[data-type="admin-siteflow-api-jobs"]');
        AdminResellerActivity.attachTo('[data-type="admin-reseller-activity"]');
        MemberNewsletter.attachTo('[data-type="admin-members-newsletter"]');
        OrderLocator.attachTo('[data-type="order-locator"]');
        MemberLocator.attachTo('[data-type="member-locator"]');
        ReviewsTrustpilotManager.attachTo('[data-type="admin-reviews-trustpilot"]');
        MemberReviewInvite.attachTo('[data-type="review-invitation"]');
        TradeprintApiJobManager.attachTo('[data-type="admin-tradeprint-post"]');
        TaylorsJobmanager.attachTo('[data-type="admin-taylors-jobs"]');
        PrintedJobManager.attachTo('[data-type="admin-printed-jobs"]');
        PrintessWebhookManager.attachTo('[data-type="admin-printess-webhook"]');
        ShopCalendarPreview.attachTo('[data-type="admin-shop-calendar"]');
        YearSelect.attachTo('[data-type="year-select"]');
        PageNavigate.attachTo('[data-type="page-navigate"]');
        OneTimeDialog.attachTo('[data-type="one-time-dialog"]');
        AnalogClock.attachTo('[data-type="analog-clock"]');
        ManualDeliveryManager.attachTo('[data-type="manual-delivery-manager"]');
        CutoffZoneManager.attachTo('[data-type="cutoff-zone"]');
        WorldShopPayments.attachTo('[data-type="world-shop-payments"]');
        OdeonList.attachTo('[data-type="admin-odeon-list"]');
        FlyeralarmJobsManager.attachTo('[data-type="admin-flyeralarm-jobs"]');
        DisputesList.attachTo('[data-type="admin-disputes"]');
        PhotoUploadManager.attachTo('[data-type="photo-upload-manager"]');
        MachinesDataTable.attachTo('[data-type="admin-machines-datatable"]');
        MachineEditor.attachTo('[data-type="admin-machine-editor"]');
        MachinePrintRunEditor.attachTo('[data-type="admin-print-run-editor"]');
        MachineMarkupEditor.attachTo('[data-type="admin-markup-editor"]');
        SupplierPageRangeEditor.attachTo('[data-type="supplier-page-range-editor"]');
        MachineAllowedProductsEditor.attachTo('[data-type="admin-machine-allowed-products-editor"]');
        MachinePriceListsEditor.attachTo('[data-type="admin-machine-price-list-editor"]');
        SupplierEditor.attachTo('[data-type="admin-supplier-editor"]');
        SupplierMachineEditor.attachTo('[data-type="admin-supplier-machine-editor"]');
        SupplierDelayEditor.attachTo('[data-type="admin-supplier-delay-editor"]');
        CkEditor.attachTo('textarea[data-mode="ckeditor"]');
        BlogCategoryDataTable.attachTo('[data-type="admin-blog-category-datatable"]');
        BlogArticleDataTable.attachTo('[data-type="admin-blog-article-datatable"]');
        AdminCustomerCredits.attachTo('[data-type="admin-customer-credits"]');
        ProductCategoryDataTable.attachTo('[data-type="admin-product-category-datatable"]');
        ProductPageDataTable.attachTo('[data-type="admin-product-page-datatable"]');
        ProductMetaDataDataTable.attachTo('[data-type="admin-product-metadata-datatable"]');
        PaperTypeWeightEditor.attachTo('[data-type="admin-paper-type-weight-editor"]');
        adminDashboard.attachTo('[data-type="admin-dashboard"]');
        PrintboxOrdersDataTable.attachTo('[data-type="admin-printbox-orders-datatable"]');

        KnowledgeBaseArticleDataTable.attachTo('[data-type="admin-knowledge-base-article-datatable"]');
        KnowledgeBaseCategoryDataTable.attachTo('[data-type="admin-knowledge-base-category-datatable"]');
        BluetreeJobsManager.attachTo('[data-type="admin-bluetree-jobs"]');
        DocumationJobsManager.attachTo('[data-type="admin-documation-jobs"]');
        AdminFormValidator.attachTo('form.needs-validation');
        AdminShopSwitcher.attachTo('[data-type="admin-shop-switcher"]');
        ProductPageMediaEditor.attachTo('[data-type="admin-product-media-editor"]');
        ProductPriceCalculatorEditor.attachTo('[data-type="admin-product-price-calculator-editor"]');

        // It's unclear whether we'll ever use this system again, but they do little harm in the admin bundle
        FormAnimSubmitter.attachTo('[data-type="form-anim-submit"]');               // Candidate
        RecruitCandidate.attachTo('[data-type="recruit-candidate"]');               // Candidate
        RecruitTester.attachTo('[data-type="recruit-tester"]');                     // Candidate

        ProductEditor.attachTo('[data-type="admin-product-editor"]');
        AdminPublicationsManager.attachTo('[data-type="admin-publications"]');
        AdminActivePublicationsManager.attachTo('[data-type="admin-active-publications"]');
        AdminPendingPublicationsManager.attachTo('[data-type="admin-pending-publications"]');
        AdminFaqManager.attachTo('[data-type="admin-faq-manager"]');
        AdminTooltipManager.attachTo('[data-type="admin-tooltip-manager"]');
        ShopNexusTable.attachTo('[data-type="admin-shop-nexus-datatable"]');
        ShopCalendarTable.attachTo('[data-type="admin-shop-calendar-datatable"]');

        CmsPageDataTable.attachTo('[data-type="admin-cms-page-datatable"]');
        CmsPageEditor.attachTo('[data-type="admin-cms-page-editor"]');
        CmsGroupDataTable.attachTo('[data-type="admin-cms-group-datatable"]');
        AdminCmsGroupManager.attachTo('[data-type="admin-cms-group-manager"]');
        FeatureRequestsDataTable.attachTo('[data-type="admin-feature-requests-datatable"]');
        FaqPageDataTable.attachTo('[data-type="admin-cms-faq-page-datatable"]');
        FaqCategoryDataTable.attachTo('[data-type="admin-cms-faq-category-datatable"]');
        ResellerFaqDataTable.attachTo('[data-type="admin-cms-reseller-faq-datatable"]');
        AdminMembersExporter.attachTo('[data-type="customer-exporter"]');
        AdminPodFulfilmentQueue.attachTo('[data-type="admin-pod-fulfilment-queue"]');
        AdminPublisherSales.attachTo('[data-type="admin-publisher-sales"]');
        AdminPublisherItemSales.attachTo('[data-type="admin-publisher-item-sales"]');
        AdminParticipationRuleBuilder.attachTo('[data-type="participation-rule-builder"]');
        ParticipationRulesDataTable.attachTo('[data-type="admin-participation-rules"]');
        AdminFulfillmentReport.attachTo('[data-type="admin-fulfillment-report"]');
        AdminPodApproval.attachTo('[data-type="publication-approval"]');
        OrderItemChargeItemsManager.attachTo('[data-type="charge-items-manager"]');
        DiscountCodesDataTable.attachTo('[data-type="admin-discount-codes-datatable"]');
        AdminDiscountCodeEditor.attachTo('[data-type="discount-code-editor"]');
        AdminDiscountCodesReport.attachTo('[data-type="admin-discount-codes-report"]');
        HomepageSettingsDataTable.attachTo('[data-type="admin-homepage-settings-datatable"]');
        AdminHomepagePriceCalcultorManager.attachTo('[data-type="admin-homepage-price-calculator-manager"]');
        AdminHomepageFeaturedProductManager.attachTo('[data-type="admin-homepage-featured-product-manager"]');
        TinyUrlDataTable.attachTo('[data-type="admin-tinyurl-datatable"]');
        AdminTinyUrlEditor.attachTo('[data-type="admin-tinyurl-editor"]');
        AdminDiversionReport.attachTo('[data-type="admin-diversion-report"]');
        AdminSmsNotifications.attachTo('[data-type="admin-sms-notifications"]');
        CreatorRegistrationDatatable.attachTo('[data-type="admin-creator-registrations"]');
        MxjdfApiJobManager.attachTo('[data-type="admin-mxjdf-api-jobs"]');
        RpiApiJobManager.attachTo('[data-type="admin-rpi-api-jobs"]');
        SaxoPrintApiJobManager.attachTo('[data-type="admin-saxoprint-api-jobs"]');
        CustomerManager.attachTo('[data-type="admin-customer-groups-customers"]');
        AdminTcoConfiguration.attachTo('[data-type="admin-tco-configuration"]');
        ShopifyWebhookManager.attachTo('[data-type="admin-shopify"]');
        ShopifyActiveProducts.attachTo('[data-type="admin-shopify-active-products-page"]');
        ApiRequestLog.attachTo('[data-type="admin-api-request-log"]');
        ApiWebhookLog.attachTo('[data-type="admin-api-webhook-log"]');
        AdminAccountsPayableDataImporter.attachTo('[data-type="admin-accounts-payable-data-importer"]');
        RedirectsDataTable.attachTo('[data-type="admin-redirects-datatable"]');
        SelfPublishingConfigDataTable.attachTo('[data-type="admin-publishing-config-datatable"]');
        AdminProductMetaDataEditor.attachTo('[data-type="admin-product-metadata-editor"]');

        // Attach Flight components from other layers...
        callback();
    });
}
export  {initializeAdmin};