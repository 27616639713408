var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Button, Table, Toast, Alert } from "react-bootstrap";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import ConfirmationModal from "./ConfirmationModal";
var SubstrateCostRow = function (_a) {
    var pressSheet = _a.pressSheet, substrateCost = _a.substrateCost, configuration = _a.configuration, awesomeQueryBuilder = _a.awesomeQueryBuilder, doGetConfiguration = _a.doGetConfiguration;
    var _b = useState(false), showDeleteModal = _b[0], setShowDeleteModal = _b[1];
    var doDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var substrateCosts, url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    substrateCosts = pressSheet.substrateCosts.filter(function (cost) {
                        return !(cost.substrate.typeId === substrateCost.substrate.typeId && cost.substrate.weightId === substrateCost.substrate.weightId);
                    });
                    url = "/api/admin/tco/configurations/".concat(configuration.machineName, "/press-sheets/").concat(pressSheet.id);
                    return [4 /*yield*/, axios.put(url, __assign(__assign({}, pressSheet), { substrateCosts: substrateCosts }))
                            .then(function () {
                            doGetConfiguration();
                            setShowDeleteModal(false);
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'success', body: 'Substrate Cost Removed', interval: 15000 } } }));
                        }).catch(function (error) {
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'error', body: "Failed to Remove Substrate Cost: ".concat(error.response.data.error), interval: 30000 } } }));
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(ConfirmationModal, { show: showDeleteModal, handleClose: function () { return setShowDeleteModal(false); }, handleSubmit: doDelete, closeButtonText: "Close", content: "Are you sure you want to delete this substrate?", saveButtonText: "Delete", heading: "Delete Press" }),
        React.createElement("tr", null,
            React.createElement("td", { className: "align-middle" }, "".concat(pressSheet.width, " x ").concat(pressSheet.height)),
            React.createElement("td", { className: "align-middle" }, awesomeQueryBuilder.substrateWeights[substrateCost.substrate.typeId][substrateCost.substrate.weightId]),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-end" }, substrateCost.cost)),
            React.createElement("td", null,
                React.createElement(Button, { variant: "danger", type: "button", onClick: function () { return setShowDeleteModal(true); } },
                    React.createElement("i", { className: "bi bi-trash" }))))));
};
var SubstrateCostModal = function (_a) {
    var showModal = _a.showModal, handleClose = _a.handleClose, configuration = _a.configuration, awesomeQueryBuilder = _a.awesomeQueryBuilder, doGetConfiguration = _a.doGetConfiguration;
    var _b = useState((configuration === null || configuration === void 0 ? void 0 : configuration.pressSheets) && configuration.pressSheets[0]), pressSheet = _b[0], setPressSheet = _b[1];
    var _c = useState({
        substrate: {
            typeId: 1,
            weightId: 1
        },
        cost: 0.00
    }), entry = _c[0], setEntry = _c[1];
    var _d = useState(false), showDuplicateIsDetectedToast = _d[0], setShowDuplicateIsDetectedToast = _d[1];
    var existingSubstrateKeys = configuration.pressSheets.map(function (sheet) {
        return (sheet.substrateCosts.map(function (substrateCost) {
            return ("".concat(sheet.id, "-").concat(substrateCost.substrate.typeId, "-").concat(substrateCost.substrate.weightId, "-").concat(substrateCost.cost, "-").concat(awesomeQueryBuilder.substrateWeights[substrateCost.substrate.typeId][substrateCost.substrate.weightId]));
        }));
    }).flatMap(function (keys) { return keys; });
    var currentNewSubstrateKey = ("".concat(pressSheet.id, "-").concat(entry.substrate.typeId, "-").concat(entry.substrate.weightId, "-").concat(entry.cost, "-").concat(awesomeQueryBuilder.substrateWeights[entry.substrate.typeId][entry.substrate.weightId]));
    var doCreate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (existingSubstrateKeys.includes(currentNewSubstrateKey)) {
                        setShowDuplicateIsDetectedToast(true);
                        return [2 /*return*/];
                    }
                    url = "/api/admin/tco/configurations/".concat(configuration.machineName, "/press-sheets/").concat(pressSheet.id);
                    return [4 /*yield*/, axios.put(url, __assign(__assign({}, pressSheet), { substrateCosts: __spreadArray(__spreadArray([], pressSheet.substrateCosts, true), [entry], false) }))
                            .then(function () {
                            doGetConfiguration();
                            handleClose();
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'success', body: 'Substrate Cost Created', interval: 15000 } } }));
                        }).catch(function (error) {
                            handleClose();
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'error', body: "Failed to Create Substrate Cost: ".concat(error.response.data.error), interval: 30000 } } }));
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSubstrateTypeChange = function (event) {
        var typeId = Number(event.target.value);
        var availableWeights = awesomeQueryBuilder.substrateWeights[typeId];
        setEntry(__assign(__assign({}, entry), { substrate: __assign(__assign({}, entry.substrate), { typeId: typeId, weightId: Number(Object.keys(availableWeights)[0]) }) }));
    };
    return (React.createElement(Modal, { show: showModal, onHide: handleClose },
        React.createElement(Modal.Header, { closeButton: true },
            React.createElement(Modal.Title, null, "Add Substrate Cost")),
        React.createElement(Modal.Body, null,
            (configuration === null || configuration === void 0 ? void 0 : configuration.pressSheets) ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "mb-4" },
                    React.createElement("label", { htmlFor: "pressSheet", className: "form-label" }, "Press Sheet"),
                    React.createElement("select", { id: "pressSheet", name: "pressSheet", className: "form-select", defaultValue: pressSheet.id, onChange: function (event) { return setPressSheet(configuration.pressSheets.find(function (sheet) { return sheet.id === event.target.value; })); } }, configuration.pressSheets.map(function (sheet) {
                        return (React.createElement("option", { key: sheet.id, value: sheet.id }, "".concat(sheet.width, " x ").concat(sheet.height)));
                    }))),
                React.createElement("div", { className: "mb-4" },
                    React.createElement("label", { htmlFor: "substrateType", className: "form-label" }, "Substrate Type"),
                    React.createElement("select", { name: "substrateType", className: "form-select", defaultValue: entry.substrate.typeId, onChange: handleSubstrateTypeChange }, awesomeQueryBuilder && Object.keys(awesomeQueryBuilder.substrateTypes).map(function (key) {
                        return (React.createElement("option", { key: awesomeQueryBuilder.substrateTypes[key], value: key }, awesomeQueryBuilder.substrateTypes[key]));
                    }))),
                React.createElement("div", { className: "mb-4" },
                    React.createElement("label", { htmlFor: "substrateWeight", className: "form-label" }, "Substrate Weight"),
                    React.createElement("select", { name: "substrateWeight", className: "form-select", defaultValue: entry.substrate.weightId, onChange: function (event) { return setEntry(__assign(__assign({}, entry), { substrate: __assign(__assign({}, entry.substrate), { weightId: Number(event.target.value) }) })); } }, awesomeQueryBuilder && Object.keys(awesomeQueryBuilder.substrateWeights[entry.substrate.typeId]).map(function (key) {
                        return (React.createElement("option", { key: awesomeQueryBuilder.substrateWeights[entry.substrate.typeId][key], value: key }, awesomeQueryBuilder.substrateWeights[entry.substrate.typeId][key]));
                    }))),
                React.createElement("div", { className: "mb-4" },
                    React.createElement("label", { htmlFor: "cost", className: "form-label" }, "Cost"),
                    React.createElement("input", { id: "cost", name: "cost", type: "number", className: "form-control", value: entry.cost, step: "0.01", onChange: function (event) { return setEntry(__assign(__assign({}, entry), { cost: Number(event.target.value) })); } })))) : (React.createElement("p", null, "Press sheets must be defined.")),
            React.createElement("div", { className: "d-flex w-100 justify-content-center" },
                React.createElement(Toast, { onClose: function () { return setShowDuplicateIsDetectedToast(false); }, show: showDuplicateIsDetectedToast, className: "w-100", autohide: true, delay: 3000 },
                    React.createElement(Alert, { variant: "primary", className: "mb-0" },
                        React.createElement("p", null, "This entry already exists, please create a unique entry and try again."))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Button, { variant: "secondary", onClick: handleClose }, "Close"),
            React.createElement(Button, { variant: "primary", onClick: doCreate }, "Save"))));
};
export function Substrates(_a) {
    var configuration = _a.configuration, awesomeQueryBuilder = _a.awesomeQueryBuilder, doGetConfiguration = _a.doGetConfiguration;
    var _b = useState(false), showModal = _b[0], setShowModal = _b[1];
    var handleCloseModal = function () { return setShowModal(false); };
    var handleShowModal = function () { return setShowModal(true); };
    return (React.createElement("div", { className: "d-flex flex-column gap-4" },
        React.createElement("div", { className: "d-flex flex-column" },
            React.createElement("h4", null, "Substrates"),
            React.createElement(Table, { striped: true, bordered: true, hover: true },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Press Sheet"),
                        React.createElement("th", null, "Substrate"),
                        React.createElement("th", null, "Cost"),
                        React.createElement("th", null))),
                React.createElement("tbody", null, configuration && configuration.pressSheets.map(function (sheet) {
                    return (sheet.substrateCosts && sheet.substrateCosts.map(function (cost) { return (React.createElement(SubstrateCostRow, { key: "".concat(sheet.id, "-").concat(cost.substrate.typeId, "-").concat(cost.substrate.weightId, "-").concat(cost.cost, "-").concat(awesomeQueryBuilder.substrateWeights[cost.substrate.typeId][cost.substrate.weightId]), pressSheet: sheet, substrateCost: cost, configuration: configuration, awesomeQueryBuilder: awesomeQueryBuilder, doGetConfiguration: doGetConfiguration })); }));
                }))),
            configuration && configuration.pressSheets.flatMap(function (sheet) { return sheet.substrateCosts; }).length === 0 && (React.createElement("p", null, "No data.")),
            React.createElement("div", { className: "d-flex flex-row gap-2 justify-content-end" },
                React.createElement("button", { type: "button", className: "align-self-end btn btn-primary", onClick: handleShowModal }, "+ Add Substrate Cost")),
            showModal &&
                React.createElement(SubstrateCostModal, { showModal: showModal, handleClose: handleCloseModal, configuration: configuration, awesomeQueryBuilder: awesomeQueryBuilder, doGetConfiguration: doGetConfiguration }))));
}
