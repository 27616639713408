import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import mixam from "../../../boot/mixam";
import { Breakpoints } from "../../table/data-table-net/StyledDataTableNet.tsx";

export default defineComponent(AdminCustomerCredits);

function AdminCustomerCredits() {

    this.attributes({
        url: "/admin/api/customer-credits/list",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        let cols = [];
        cols.push({
            title: "Customer Name",
            type: "link",
            data: "name"
        });
        cols.push({
            title: "Credit Type",
            type: "text",
            data: "type"
        });
        cols.push({
            title: "Amount",
            type: "number",
            decimal: 2,
            currency: true,
            data: "amount"
        });
        cols.push({
            title: "Spent",
            type: "number",
            decimal: 2,
            currency: true,
            data: "amountSpent"
        });
        cols.push({
            title: "Remaining",
            type: "number",
            decimal: 2,
            currency: true,
            data: "amountRemaining"
        });
        cols.push({
            title: "Request Date",
            type: "timebox",
            data: "requestDate",
            defaultSort: true,
            sortOrder: 'desc',
            hide: Breakpoints.MD
        });
        cols.push({
            title: "Issue Date",
            type: "timebox",
            data: "issueDate",
            hide: Breakpoints.MD
        });
        cols.push({
            title: "Expiry Date",
            type: "timebox",
            data: "expiryDate",
            hide: Breakpoints.LG
        });
        cols.push({
            title: "Status",
            type: "text",
            data: "status",
            hide: Breakpoints.LG
        });
        cols.push({
            title: "Requested By",
            type: "text",
            data: "requestedBy",
            hide: Breakpoints.XL
        });
        cols.push({
            title: "Issued By",
            type: "text",
            data: "issuedBy",
            hide: Breakpoints.XL
        });
        cols.push({
            title: "Internal Notes",
            type: "text",
            data: "internalNotes",
            width: '300px',
            hide: Breakpoints.XXL
        });
        cols.push({
            title: "Action",
            type: "customer-credit-modal",
            data: "customerCreditRequest",
            width: "100px"
        });
        return cols;
    };

    this.normalize = function (list) {
        return list.map(request => this.normalizeData(request));
    };

    this.normalizeData = function (request) {
        const data = $.extend(true, {}, request);
        data.name = {
            href: `/member/${data.memberId}/admin`,
            caption: data.customerName,
            title:  data.customerName
        };
        data.customerCreditRequest = data;
        return data;
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
           });
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data)
        };
        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.subscribe = function (onMessage) {
        this.stomp = Stomp.client(mixam.stompServiceUrl);
        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Lost connection to')  !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
        };
        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/allcustomercreditschannel", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateMessageLine = function (data) {
        if (!this.data) {
            return;
        }

        let verb = data.type;
        const message = data.data;

        const index = this.data.map(x => x.id).indexOf(message.id);

        if (verb === "UPDATE" || verb === "INSERT") {
            if (index !== -1) {
                this.data[index] = message;
            } else {
                this.data.unshift(message);
            }
        }

        this.paint();
        requestAnimationFrame(() => $("#PK" + message.id).hilightTableLine());
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        this.subscribe(d => this.updateMessageLine(d));
    });
}