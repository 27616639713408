import bindIcons from '../constants/binding-icons';
import { getProductMetadataByAll } from '../home/getProductMetadata';
import Product from '../constants/products';
import OrderStatus from "../constants/orderStatus";
import Order from '../constants/order';

export default function WithOrdersItems() {

    this.getData = function () {
        requestAnimationFrame(() => this.signDataRequest(token => this.getReportData(token)));
    };

    this.getReportData = function (token) {
        const url = this.hasConnection ? this.attr.url : this.attr.firstTimeUrl;
        const params = {
            status: this.attr.status ? this.attr.status : Order.ORDER_STATUS_ORDER,
            minStatus: this.attr.minStatus ? this.attr.minStatus : undefined,
            maxStatus: this.attr.maxStatus ? this.attr.maxStatus : undefined,
            podQueueId: this.attr.podQueueId ? this.attr.podQueueId : undefined
        };

        $.ajax({url: url, data: params, dataType: 'json', headers: {'Authorization': `Bearer ${token}`}})
            .done(data => {
                this.data = data;
                this.paint();
                if (this.hasConnection) {
                    setTimeout(() => this.getData(), 1000 * 60 * 15);
                } else {
                    this.hasConnection = true;
                    setTimeout(() => this.getData(), 1000 * 5);
                }
            })
            .fail(err => this.trigger("log", {message: err}));
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalizeOrderItems(this.data),
            "class": 'table-striped table-order-list'
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
            this.clock();
        }
    };

    this.updateOrderLine = function (data) {
        if (!this.data) {
            return;
        }
        if (this.isRelevantMessage(data)) {
            const order = data.order;
            const index = this.data.map(x => x.id).indexOf(order.id);

            if (index !== -1) {
                this.data[index] = order;
                //console.log("update index", index, this.data);
            } else {
                this.data.unshift(order);
                //console.log("update first", this.data);
            }
            this.paint();
            window.dispatchEvent(new CustomEvent('highlightDataTableRow', {detail: {rowToHighlightId: order.id}}));
        }
    };

    this.normalizeOrderItems = function (list) {
        const data = [];
        list.forEach(order => {
            if (order.item) {
                data.push(this.normalizeOrderItem(order.item, order, order.index));
            } else {
                order.items.forEach((item, i) => {
                    data.push(this.normalizeOrderItem(item, order, i));
                });
            }
        });
        return data;
    };

    this.normalizeOrderItem = function (item, order, index) {
        const result = $.extend(true, {}, item);
        const {thumb, title, orientation} = this.getThumbUrl(item);
        result.override = result.override || {};
        result.override.td = result.override.td || {};
        // result.override.td.orderStatus = "label-" + order.orderStatus;
        result.override.td.status = "label-" + (item.status || order.orderStatus);
        result.override.td.settled = "verify-" + order.isPaymentVerified;
        result.order = order;
        result.itemId = (item.id || order.itemId);
        result.id = order.id + result.itemId;
        result.orderType = item.orderType; // Used in AdminItemsManager in connection w/ Fourthwall
        result.customerGroupsList = order.member && order.member.customerGroupReferences ?
            order.member.customerGroupReferences.map(customerGroup => customerGroup.name) :
            [];

        if (thumb) {
            result.front = {
                src: thumb,
                href: `/orders/${order.id}/artwork`,
                title: title,
                target: "_blank"
            };
            result.override.td.front = "orient-" + orientation;
        }

        const itemCount = order.itemCount || order.items.length;
        result.href = {
            href: `/orders/${order.id}/artwork`,
            caption: order.caseNumber + (itemCount > 1 ? "/" + (index + 1) : ""),
            title: title,
            target: "_blank"
        };
        result.freeTest = order.freeTest;
        result.time = order.time;
        result.lastModifiedDate = order.lastModifiedDate;
        result.confirmDate = order.confirmDate;
        result.deliveryDate = item.fulfillment && item.fulfillment.date;
        result.shop = this.shops[order.shopId] && this.shops[order.shopId].icon;
        result.isDigital = item.response.printType === "DIGITAL";
        result.isInkjet = item.response.printType === "INKJET";
        result.isWideFormat = item.response.printType === "WIDE_FORMAT";
        result.isLitho = item.response.printType === "LITHO";
        result.isReprint = !!item.reprint?.caseNumber;

        if (item.query) {
            let productMetaData = getProductMetadataByAll(item.query.productId, item.query.subProductId);
            result.product = productMetaData.productName;
            result.bound = item.query.coverType > 0 ? "bi bi-book" : "";
            result.pages = item.query.pages || 0;
            result.sides = item.query.sides || 0;
            if (item.query.productId === Product.BOOKLET || item.query.productId === Product.BOOK || item.query.productId === Product.PHOTOBOOK) {
                result.bind = bindIcons[item.query.bind || 0];
            }
            result.isSewn = item.query.sewing > 0;
            result.copies = item.query.copies || 0;
            result.size = 'A' + item.query.format;
            if(item.query.secondaryFormat !== 0) {
                let standardSize = productMetaData.standardSizes.find((standardSize) => {return standardSize.secondaryFormat === item.query.secondaryFormat;});
                if(standardSize) {
                    result.size = standardSize.label;
                }
            }
            result.calcedPages = item.query.pages;
            if (item.query.version < 2 && item.query.pages > 0 && item.query.coverType > 0) {
                result.calcedPages -= 4;
            }
            if (typeof item.query.orientation !== 'undefined') {
                result.orientation = this.orientationIcons[item.query.orientation];
            }
            let substrateType = productMetaData.substrateTypes.find((substrateType) => {return substrateType.id === item.query.paperFinish;});
            if(substrateType) {
                result.substrateType = substrateType.label;
                let substrateColour = substrateType.substrateColours.find((substrateColour) => {return substrateColour.id === item.query.paperFinishColor;});
                if(substrateColour) {
                    let weight = substrateColour.weights.find((weight) => {return weight.id === item.query.paper;});
                    if(weight) {
                        result.substrateWeight = weight.label;
                    }
                }
            }

        } else {
            this.trigger("log", {message: `Item without query ${item.id}`, title: "AdminItemsManager"});
        }
        const cost = item.fulfillment && item.fulfillment.cost || 0;

        result.value = (item.discountTotal || item.total) - cost;
        result.expectedCost = cost;

        if (item.fulfillmentSum) {
            result.overflow = item.fulfillmentSum - cost;
            result.overflowPercent = result.overflow / cost * 100;
            result.hasRemark = Boolean(item.fulfillmentRemark);
        } else {
            result.fulfillmentSum = -1;
        }
        result.fulfillmentSumDisplay = result.fulfillmentSum === -1 ? '' : result.fulfillmentSum;
        result.packagingCost = result.fulfillment?.packagingCost ? result.fulfillment.packagingCost : 0;

        result.fulfillmentRemark = item.fulfillmentRemark;

        if (order.member) {
            result.user = {
                name: order.member.firstName + " " + order.member.lastName,
                email: order.member.email,
                phone: order.contact.phone,
                confirmedItemCount: order.member.confirmedItemCount,
                confirmedOrderCount: order.member.confirmedOrderCount,
                dateCreated: order.member.dateCreated,
                description: order.member.description,
                id: order.member.id,
                lastModifiedDate: order.member.lastModifiedDate,
                lastSigninDate: order.member.lastSigninDate,
                photo: order.member.photo,
                purchasesCount: order.member.purchasesCount,
                remark: order.member.remark,
                status: 1
            };
            result.verticalMarkets = order.member.verticalMarkets && order.member.verticalMarkets.map(verticalMarket => verticalMarket.name);
            result.customerGroups = order.member.customerGroupReferences && order.member.customerGroupReferences.map(customerGroupReference => customerGroupReference.name);
        } else {
            result.user = {
                name: order.contact.name,
                email: order.contact.email,
                phone: order.contact.phone
            };
        }

        result.isHasExpress = order.item ? order.item.response.isExpress : order.isHasExpress;
        result.status = result.status || order.orderStatus;
        result.statusBadge = {
            label: result.status,
            colour: OrderStatus.getBadgeColour(order.orderStatusInt)
        };
        result.settled = order.settled;
        result.expectedDate = order.expectedDate;
        /* jshint ignore:start */
        result.artwork = item.map?.ready ? 'fa-circle' : (this.isMapNotEmpty(item.map) ? 'fa-dot-circle-o' : 'fa-circle-o');
        /* jshint ignore:end */
        result.supplier = item.fulfillment && item.fulfillment.supplierName;
        result.fulfilmentMachine = item.fulfillment && item.fulfillment.machine;
        result.offerMachine = item.response.machineName;
        result.offerSupplier = item.response.providerName;
        result.fulfilmentUpdate = result;
        result.podFulfilmentCheckbox = {
            checked: true,
            name: 'fulfil-item-checkbox',
            value: '',
            orderId: order.id,
            itemId: result.itemId
        };
        result.publicationTitle = result.publicationConfig ? result.publicationConfig.title : '';
        result.publicationAuthors = result.publicationConfig ? result.publicationConfig.authors.map(author => {return author.name;}).join(', ') : '';
        if(result.share) {
            result.publicationViewAction = {
                href: `/print-on-demand/${result.share.id}`,
                caption: 'View Publication',
                target: '_blank',
                className: 'btn btn-sm btn-primary'
            };
        }
        result.isShrinkWrapped = item.query.itemSpecification.components.find(c => c.componentType === "SHRINK_WRAP") ? true : false;
        result.printOnDemandTitle = result.printOnDemandItem ? result.printOnDemandItem.title : '';
        result.printOnDemandItemSource = result.printOnDemandItem ? result.printOnDemandItem.source : '';
        result.printOnDemandItemReferer = result.printOnDemandItem ? result.printOnDemandItem.referer: '';
        return result;
    };
}
