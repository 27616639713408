import defineComponent from '../../../components/flight/lib/component';
import Utils from "../../../components/flight/lib/utils";
import axios from "axios";
export default defineComponent(Header);

function Header() {

    this.attributes({
        searchUrl: '/search',
        productCategoryMenuItemSelector: '[data-type="product-category-menu-item"]',
        megaMenuSelector: '[data-type="mega-menu"]',
        megaMenuCategoryListSelector: '[data-type="mega-menu"] [data-type="category-list"]',
        megaMenuCategoryTabsSelector: '[data-type="mega-menu"] [data-type="product-list"]',
        allProductsButtonSelector: '[data-type="all-products-btn"]',
        productCategoriesMenuSelector: '[data-type="product-categories-menu"]',
        searchBarSelectorSelector: '[data-type="search-bar"]',
        searchBarContainerSelector: '[data-type="product-search-bar"]',
        searchResultsContainerSelector: '[data-type="search-results"]',
        mobileSearchButtonSelector: '[data-type="mobile-search-btn"]',
        mobileHeaderContainerSelector: '[data-type="mobile-header"]',
        mobileSearchBtnCloseSelector:'[data-type="mobile-search-btn-close"]',
        mobileMenuSelectorSelector: '[data-type="mobile-menu"]'
    });

    this.onMenuButtonOrProductCategoryMenuItemClick = function(event) {
        const menuItem = $(event.target);
        const targetCategory = menuItem.data('target-category') === undefined ? 'all-products' : menuItem.data('target-category');
        const currentCategory = this.select('megaMenuCategoryListSelector').find('li.active').data('category');
        const allProductsCategory = this.select('allProductsButtonSelector').data('target-category');

        if (this.isMegaMenuOpen && (targetCategory === currentCategory || targetCategory === allProductsCategory)) {
            this.hideMegaMenu();
        } else {
            this.showMegaMenu(targetCategory);
        }
    };

    this.showMegaMenu = function(targetCategory) {
        const header = this;
        if(!this.isMegaMenuOpen) {
            this.select('megaMenuSelector').collapse('show');
            this.select('allProductsButtonSelector').find('i').removeClass('fa-chevron-down').addClass('fa-chevron-up');
        }

        this.select('megaMenuCategoryListSelector').find('li').removeClass('active');
        this.select('megaMenuCategoryListSelector').find(`li[data-category="${targetCategory}"]`).addClass('active');

        this.select('megaMenuCategoryTabsSelector').find('.tab-pane').removeClass('active');
        this.select('megaMenuCategoryTabsSelector').find(`.tab-pane[data-category-tab="${targetCategory}"]`).addClass('active');

        // Listener For Clicking Outside The Menu To Close It
        $(document).one('click', function closeMenu (e){
            if(header.select('megaMenuSelector').has(e.target).length === 0 && header.select('productCategoriesMenuSelector').has(e.target).length === 0 && !header.select('allProductsButtonSelector').is(e.target)) {
                header.hideMegaMenu();
            } else {
                $(document).one('click', closeMenu);
            }
        });
        this.isMegaMenuOpen = true;
    };

    this.hideMegaMenu = function() {
        if(this.isMegaMenuOpen) {
            this.select('megaMenuSelector').collapse('hide');
            this.select('allProductsButtonSelector').find('i').removeClass('fa-chevron-up').addClass('fa-chevron-down');
        }
        this.isMegaMenuOpen = false;
    };

    this.search = function () {
        const header = this;
        const searchQuery = (this.select('searchBarSelectorSelector').val() || "").trim();
        if (searchQuery && searchQuery.length >= 3) {
            axios.post(`${this.attr.searchUrl}`,  null, {
                params: {
                    searchQuery: searchQuery
                },
            })
            .then(response => {
                header.showSearchResults(response.data);
            })
            .catch(error => {

            });
        } else {
            header.hideSearchResults();
        }
    };

    this.showSearchResults = function (searchResults) {
        const header = this;
        const searchResultsList = searchResults.length === 0 ? `<h5>No Results Found</h5>` : searchResults.map((searchResult) => {
           return `
                <a href="${searchResult.path}">
                    <div class="searchResultItem">
                        <div class="searchResultImage">
                            <img src="${searchResult.imgUrl}"/>
                        </div>
                        <div class="searchResultDescription">
                            <h5>${searchResult.title}</h5>
                            <p>${searchResult.snippet}</p>
                        </div>
                    </div>
                </a>
           `;
        });
        this.select('searchResultsContainerSelector').html(searchResultsList);
        this.select('searchResultsContainerSelector').collapse('show');
        $(document).one('click', function closeSearchResults (e){
            if(header.select('searchResultsContainerSelector').has(e.target).length === 0) {
                header.hideSearchResults();
            } else {
                $(document).one('click', closeSearchResults);
            }
        });
    };

    this.hideSearchResults = function () {
        this.select('searchResultsContainerSelector').collapse('hide');
    };

    this.showMobileSearch = function () {
        this.select('mobileHeaderContainerSelector').hide();
        this.select('searchBarContainerSelector').addClass('in');
    };

    this.closeMobileSearch = function () {
        this.select('mobileHeaderContainerSelector').show();
        this.select('searchBarContainerSelector').removeClass('in');
    };

    this.onMobileSearchBtnClick = function () {
        this.showMobileSearch();
    };

    this.initOverlayTrigger = function () {
        const mobileMenu = this.select('mobileMenuSelectorSelector');
        mobileMenu.on('shown.bs.collapse', function (e) {
            if(e.target.getAttribute('data-type') === 'mobile-menu') {
                document.body.classList.add('overflow-hidden');
            }
        });

        mobileMenu.on('hide.bs.collapse', function (e) {
            if(e.target.getAttribute('data-type') === 'mobile-menu') {
                document.body.classList.remove('overflow-hidden');
            }
        });
    };

    this.after('initialize', function () {
        this.isMegaMenuOpen = false;
        this.on("click", {
            productCategoryMenuItemSelector: this.onMenuButtonOrProductCategoryMenuItemClick,
            allProductsButtonSelector: this.onMenuButtonOrProductCategoryMenuItemClick,
            searchBarSelectorSelector: this.search,
            mobileSearchButtonSelector: this.onMobileSearchBtnClick,
            mobileSearchBtnCloseSelector: this.closeMobileSearch
        });
        this.on('keyup', {
            searchBarSelectorSelector: Utils.debounce((event) => this.search(), 200)
        });

        this.initOverlayTrigger();

    });
}
