import defineComponent from '../../../components/flight/lib/component';
import WithGoogleAnalytics from './../with-google-analytics';
import axios from "axios";

export default defineComponent(ChargesEditor, WithGoogleAnalytics);

function ChargesEditor() {

    this.attributes({
        cancelChargeBtnSelector: '[data-type="cancel-charge"]',
        editOrderBtnSelector: '[data-type=edit-order]',
        orderTableSelector: '[data-type="table-display"]',
        orderEditSelector: '[data-type="admin-order-edit-form"]',
        alterDescriptionSelector: '[data-type="alternative-description"]',
        originalDescriptionSelector: '[data-type="original-description"]',
        customSizeToggleSelector: '[data-action="change-custom-size"]',
        customSizeInputSelector: '[data-type="change-custom-input"]',
        toggleDescriptionSelector: '[data-type="toggle-description"]',
        reprintToggleSelector: '[data-type="toggle-reprint"]',
        reprintOrderNumberInputSelector: '[data-type="reprint-order-number"]',
        removeDiscountCodeSelector: '[data-type="remove-discount-code"]',
    });

    this.startOrderEdit = function (event) {
        event.preventDefault();
        this.select("orderTableSelector").hide();
        this.select("orderEditSelector").show("slow");
    };

    this.endOrderEdit = function (event) {
        event.preventDefault();
        this.select("orderEditSelector").hide();
        this.select("orderTableSelector").show("slow");
    };

    this.toggleDescription = function (event) {
        if (event.target.checked) {
            this.select('alterDescriptionSelector').hide("normal").attr("disabled", true);
            this.select('originalDescriptionSelector').show("slow");
        } else {
            this.select('originalDescriptionSelector').hide("normal");
            this.select('alterDescriptionSelector').show("slow").attr("disabled", null);
        }
    };

    this.toggleCustomSize = function (event) {
        const stateOn = $(event.target).prop('checked');
        this.select('customSizeInputSelector').prop('disabled', !stateOn);
    };

    this.toggleReprint = function (event) {
        const stateOn = $(event.target).prop('checked');
        this.select('reprintToggleSelector').val(stateOn);
        this.select('reprintOrderNumberInputSelector').prop('disabled', !stateOn).prop('required', stateOn);
        if(stateOn) {
            this.select('reprintOrderNumberInputSelector').show();
        } else {
            this.select('reprintOrderNumberInputSelector').hide();
        }
    };

    this.removeDiscountCode = function (event) {
        event.preventDefault();
        const target = $(event.target);
        const discountCode = target.data('discount-code');
        const orderId = target.data('order-id');

        axios.delete(`/api/orders/${orderId}/discount-codes/${discountCode}`)
            .then(response => {
                location.reload();
            }).catch(reason => {
                console.log("Error Removing Disocunt Code", reason);
            });
    };

    this.after('initialize', function () {
        this.on('click', {
            editOrderBtnSelector: this.startOrderEdit,
            cancelChargeBtnSelector: this.endOrderEdit,
            customSizeToggleSelector: this.toggleCustomSize,
            toggleDescriptionSelector: this.toggleDescription,
            reprintToggleSelector: this.toggleReprint,
            removeDiscountCodeSelector: this.removeDiscountCode
        });
    });
}