import defineComponent from '../../../components/flight/lib/component';
import mixam from '../../../appes6/boot/mixam';

export default defineComponent(CookieConsentManager);

function CookieConsentManager() {

    this.attributes({
        analyticsToggleSelector: '[data-type="analytics-cookies-toggle"]',
        adStorageToggleSelector: '[data-type="ad-storage-toggle"]',
        adPersonalizationToggleSelector: '[data-type="ad-personalization-toggle"]',
        adUserDataToggleSelector: '[data-type="ad-user-data-toggle"]',
        cookieBannerSelector: '[data-type="cookie-consent-banner"]',
        modalSelector: '[data-type="cookie-consent-modal"]',
        acceptAllCookiesBtnSelector: '[data-type="accept-all-cookies-btn"]',
        saveCookieSettingsBtnSelector: '[data-type="save-cookie-settings-btn"]'
    });

    const CONSENT_GRANTED = 'granted';
    const CONSENT_DENIED = 'denied';

    this.acceptAll = function () {
        const consentSettings = {
            'analytics_storage': CONSENT_GRANTED,
            'ad_storage': CONSENT_GRANTED,
            'ad_personalization': CONSENT_GRANTED,
            'ad_user_data': CONSENT_GRANTED
        };
        this.updateConsent(consentSettings);
        this.storeConsent(consentSettings);
        this.hideCookieBanner();
    };

    this.savePreferences = function () {
        const analyticsEnabled = this.select('analyticsToggleSelector').prop('checked');
        const adStorageEnabled = this.select('adStorageToggleSelector').prop('checked');
        const adPersonalizationEnabled = this.select('adPersonalizationToggleSelector').prop('checked');
        const adUserDataEnabled = this.select('adUserDataToggleSelector').prop('checked');

        const consentSettings = {
            'analytics_storage': analyticsEnabled ? CONSENT_GRANTED : CONSENT_DENIED,
            'ad_storage': adStorageEnabled ? CONSENT_GRANTED : CONSENT_DENIED,
            'ad_personalization': adPersonalizationEnabled ? CONSENT_GRANTED : CONSENT_DENIED,
            'ad_user_data': adUserDataEnabled ? CONSENT_GRANTED : CONSENT_DENIED
        };

        this.updateConsent(consentSettings);
        this.storeConsent(consentSettings);
        this.hideModal();
        this.hideCookieBanner();
    };

    this.updateConsent = function (consentSettings) {
        gtag('consent', 'update', consentSettings);
        gtag('event', 'gtag_consent_updated', {
            'consent_data': consentSettings,
            event_callback: function() {
                window.dataLayer.push({
                    event: 'gtag_consent_updated',
                    consent_data: consentSettings
                });
            }
        });
    };

    this.showCookieBanner = function () {
        this.select('cookieBannerSelector').css('display', 'flex');
    };

    this.hideCookieBanner = function () {
        this.select('cookieBannerSelector').css('display', 'none');
    };

    this.showModal = function () {
        $(this.select('modalSelector')).modal('show');
    };

    this.hideModal = function () {
        $(this.select('modalSelector')).modal('hide');
    };

    this.storeConsent = function (consentSettings) {
        localStorage.setItem("cookieConsentSettings", JSON.stringify(consentSettings));
    };

    this.checkCookieSettingStatus = function() {
        const cookieConsentSettings = localStorage.getItem('cookieConsentSettings');
        if (!cookieConsentSettings && Boolean(mixam.cookiePopupEnabled) === true) {
            this.showCookieBanner();
        }
    };

    this.after('initialize', function () {

        this.checkCookieSettingStatus();

        this.on('click', {
            'acceptAllCookiesBtnSelector': this.acceptAll,
            'saveCookieSettingsBtnSelector': this.savePreferences
        });
    });

}
