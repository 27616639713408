import {initializePublic} from "./page-public";

// Mixam Flight Components
import OrderSummary from "./appes6/component_ui/order/order-summary";
import OrderVatUpdater from "./appes6/component_ui/order/order-vat-updater";
import memberManager from "./appes6/component_ui/member/member-manager";
import MemberOrdersManager from "./appes6/component_ui/member/orders/member-orders-manager";
import PreviewManager from "./appes6/component_ui/preview/preview-manager";
import OrderGroupDeliveryManager from "./appes6/component_ui/order/order-group-delivery-manager";
import Folding3dViewer from "./appes6/component_ui/order/folding-3d-viewer";
import InOrderSantaManager from "./appes6/component_ui/order/in-order-santa-manager";
import DateCountDown from "./appes6/component_ui/order/date-count-down";
import WebGlViewManager from "./appes6/component_ui/order/webgl-viewer-manager";
import OrderReadiness from "./appes6/component_ui/order/order-readiness";
import StripeManager from "./appes6/component_ui/order/payment/stripe-manager";
import SlideOnReady from "./appes6/component_ui/slide-on-ready";
import AmazonPayAddress from "./appes6/component_ui/order/payment/amazon-pay-address-widget";
import AmazonPayWidget from "./appes6/component_ui/order/payment/amazon-pay-widget";
import DigitalWalletManager from "./appes6/component_ui/order/digital-wallet-manager";
import AddressStreetView from "./appes6/component_ui/order/address-street-view";
import TaxExemption from "./appes6/component_ui/member/tax-exemption";
import AfterpayManager from "./appes6/component_ui/order/payment/afterpay";
import PaypalManager from "./appes6/component_ui/order/payment/paypal";
import VoltPay from "./appes6/component_ui/order/payment/volt-pay";
import CreditKeyManager from "./appes6/component_ui/order/payment/creditkey";
import MonduManager from "./appes6/component_ui/order/payment/mondu";
import ResellerConfig from './appes6/component_ui/reseller/reseller-config';
import StripeConnectAccountManager from "./appes6/component_ui/member/member-stripe-connect-account-manager";
import PlaidManager from "./appes6/component_ui/order/payment/plaid";

import {initialisePublicationSettings, initialisePublicationSpawner} from "./appes6/component_ui/react/self-publishing/PublicationSettings/index.tsx";
import {initialiseSalesHistory} from "./appes6/component_ui/react/self-publishing/SalesHistory/index.tsx";
import {initialisePublications} from "./appes6/component_ui/react/self-publishing/Publications/index.tsx";

function initializePrivate(callback = () => {}) {

    initializePublic(() => {

        // Attach Flight components...
        OrderSummary.attachTo(".order-summary-container");                          // Order Manager
        OrderVatUpdater.attachTo(".order-summary-container");                       // Order Manager
        memberManager.attachTo("#member #main");                                    // Member Manager
        MemberOrdersManager.attachTo('[data-type="member-orders"]', {
            mode: "orders"
        });                                                                         // Member Orders Manager
        MemberOrdersManager.attachTo('[data-type="member-quotes"]', {
            mode: "quotes"
        });                                                                         // Member Quotes Manager
        PreviewManager.attachTo('[data-type="preview-manager"]');                   // Preview
        OrderGroupDeliveryManager.attachTo('[data-type="order-delivery-manager"]'); // Order Manager
        Folding3dViewer.attachTo('[data-type="folding-3d-viewer"]');                // Order Manager
        InOrderSantaManager.attachTo('[data-component="edit-in-order-santa"]');     // Order Manager
        DateCountDown.attachTo('[data-type="date-count-down"]');                    // Order Manager
        WebGlViewManager.attachTo('[data-type="wgl3d"]');                           // Order Manager
        OrderReadiness.attachTo('[data-type="order-readiness-dialog"]');            // Order Manager
        StripeManager.attachTo('[data-type="pay-stripe"]');                         // Order Manager
        SlideOnReady.attachTo('[data-type="slide-on-ready"]');                      // Order Manager
        AmazonPayAddress.attachTo('[data-type="amazon-pay-address"]');              // Order Manager
        AmazonPayWidget.attachTo('[data-type="amazon-pay-widget"]');                // Order Manager
        DigitalWalletManager.attachTo('[data-type="pay-digital-wallet"]');          // Order Manager
        AddressStreetView.attachTo('[data-type="address-street-view"]');            // Order Manager
        TaxExemption.attachTo('[data-form="tax-exemption"]');                       // Member Tax Exemption Form
        AfterpayManager.attachTo('[data-type="pay-afterpay"]');
        PaypalManager.attachTo('[data-type="pay-paypal"]');
        VoltPay.attachTo('[data-type="pay-volt"]');
        CreditKeyManager.attachTo('[data-type="pay-creditkey"]');
        MonduManager.attachTo('[data-type="pay-mondu"]');
        ResellerConfig.attachTo('[data-type="reseller-config"]');
        StripeConnectAccountManager.attachTo('[data-type="stripe-connect-account"]');
        PlaidManager.attachTo('[data-type="pay-plaid"]');
        // Attach Flight components from other layers...
        callback();

        initialisePublicationSettings();
        initialisePublicationSpawner();
        initialiseSalesHistory();
        initialisePublications();

    });

}

export  {initializePrivate};
