import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import DataTable from '../table/data-table-net/data-table-net.tsx';
import WithOrdersItemsMembers from './with-orders-items-members';
import WithOrdersItems from './with-order-items';
import WithSignDataRequest from './../with-sign-data-request';
import { Breakpoints } from '../table/data-table-net/StyledDataTableNet.tsx';
import Order from "../constants/order";

export default defineComponent(AdminItemsManager, WithOrdersItemsMembers, WithOrdersItems, WithSignDataRequest);

function AdminItemsManager() {

    this.attributes({
        firstTimeUrl: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/orders/items/first`,
        url: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/orders/items`,
        status: Order.ORDER_STATUS_ORDER,
        popoverSelector: '[data-toggle="popover"]',
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "",
            type: "thumbnail",
            data:"front"
        });
        cols.push({
            title: "Order",
            type: "link",
            data:"href",
            width: "5rem",
        });
        cols.push({
            title: "Username",
            type: "user",
            data:"user",
            width: "10rem",
            hide: Breakpoints.SM,
        });
        cols.push({
            title: "Groups",
            type: "list",
            data: "customerGroupsList",
            hide: Breakpoints.LG,
            width: '150px'
        });
        cols.push({
            title: "Modified",
            type: "timebox",
            data:"lastModifiedDate",
            hide: Breakpoints.MD,
        });
        cols.push({
            title: "Status",
            type: "badge",
            data:"statusBadge",
            width: "150px",
            hide: Breakpoints.MD,
        });
        cols.push({
            title: "Confirmed",
            type: "timebox",
            data:"confirmDate",
            hide: Breakpoints.MD,
        });
        cols.push({
            title: "Reprint",
            type: "boolean",
            width: "5%",
            data: "isReprint"
        });
        cols.push({
            title: "Type",
            type: "order-type",
            data: "orderType",
            hide: Breakpoints.XL
        });
        cols.push({
            title: "Product",
            type: "text",
            data:"product",
            width: "10rem",
            hide: Breakpoints.LG,
        });
        cols.push({
            title: "Copies",
            type: "number",
            data:"copies",
            hide: Breakpoints.LG,
        });
        cols.push({
            title: "Size",
            type: "text",
            data: "size",
            width: "10rem",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Substrate Type",
            type: "text",
            data: "substrateType",
            width: "10rem",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Substrate Weight",
            type: "text",
            data: "substrateWeight",
            width: "6rem",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Sides",
            type: "number",
            decimal: 0,
            data: "sides",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Pages",
            type: "number",
            data: "calcedPages",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Orientation",
            type: "icon",
            data: "orientation",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Bound",
            type: "icon",
            data: "bound",
            hide: Breakpoints.XXL
        });
        cols.push({
            title: "Bind",
            type: "icon",
            data: "bind",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Sewn",
            type: "boolean",
            data: "isSewn",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Foiled",
            type: "boolean",
            data: "isFoiled",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Dust Jacket",
            type: "boolean",
            data: "hasDustJacket",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Shrink Wrapped",
            type: "boolean",
            data: "isShrinkWrapped",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Artwork",
            type: "icon",
            data: "artwork",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Settled",
            type: "boolean",
            data: "settled",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Sample Test",
            type: "boolean",
            data: "freeTest",
            hide: Breakpoints.MD,
        });
        cols.push({
            title: "Express",
            type: "boolean",
            data: "isHasExpress",
            hide: Breakpoints.MD,
        });
        cols.push({
            title: "Digital",
            type: "boolean",
            data: "isDigital",
            hide: Breakpoints.LG,
        });
        cols.push({
            title: "Offer Supplier",
            type: "text",
            data: "offerSupplier",
            width: "10rem",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Offer Machine",
            type: "text",
            data: "offerMachine",
            width: "10rem",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Supplier",
            type: "text",
            data: "supplier",
            width: "10rem",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Machine",
            type: "text",
            data: "fulfilmentMachine",
            width: "10rem",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Sum",
            type: "number",
            data: "total",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix
        });
        cols.push({
            title: "Value",
            type: "number",
            data: "value",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix
        });

        return cols;
    };

    this.after('initialize', function () {

        DataTable.attachTo(this.select('dataTableSelector'));

        this.on(document, "click.ask.for.notify.permissions", this.askForNotifyPermissions);

        setTimeout(() => this.getData(), 10);

        try {
            this.subscribe(this.updateOrderLine.bind(this));
        } catch (e) {

        }
    });
}
