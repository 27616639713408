import mixam from "../../boot/mixam";
import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(InOrderSantaManager);

function InOrderSantaManager() {

    this.attributes({
        launchBtnSelector: '.santa-launch',
        containerSelector: '.in-order-santa-container',
        artworkSelector: '.order-row',
        cancelBtnSelector: '[data-type="cancel-in-order-santa"]',
        contentSelector: '[data-type="santa-content-holder"]',
    });

    this.handleClick = function () {
        this.generatePriceCalculator();
        setTimeout(() => {
            this.select('artworkSelector').hide();
            this.select('containerSelector').show('fast');
        }, 10);

        $('[data-type="order-container"]').removeClass('clipped');
    };

    this.generatePriceCalculator = function() {
        // NOTE: single quote on some attributes below b/c the JSON contains double quotes
        const santaType = ((status) => {
            // Be certain to get the santaType correct...
            switch (status) {
                case 'order':
                    return 'QUOTE';
                case 'publication':
                    return 'PUBLICATION';
                case 'print-on-demand':
                    return 'PRINT_ON_DEMAND';
            }
        })(this.order.status);
        const html = `
            <price-calculator
                    data-type="price-calculator"
                    secureDomain="${mixam.secureDomain}"
                    locale="${mixam.shop.locale.replace('_', '-')}"
                    theme='${mixam.shop.themeJson && mixam.shop.themeJson}'
                    data='{"productName":"${this.item.query.productName}", "santaType":"${santaType}", "queryResponseTopicId":"${mixam.queryResponseTopicId}", "data":[{"subProductId":${this.item.query.subProductId}, "productId":${this.item.query.productId}}]}'
                    order="${this.order.id}"
                    item="${this.item.id}"
                    itemSpecification='${JSON.stringify(this.item.query.itemSpecification)}'
                    isSelfPublication="${this.$node.data('pod') || 'false'}"
                    showPrintTemplate="false"
                    timezone="${mixam.shop.timeZone}"
                    useReactCart="true"
                >
                <div class="text-center">
                    <div>Loading Price Calculator</div>
                    <div class="h1">
                        <i class="fa fa-spin fa-spinner"></i>
                    </div>
                </div>
            </price-calculator>
        `;
        this.select('contentSelector').html(html);
    };

    this.handleCancelClick = function () {
        this.select('containerSelector').hide();
        this.select('artworkSelector').show('fast');
        $('[data-type="order-container"]').addClass('clipped');
    };

    this.setOrder = function (event, data) {
        this.order = data;
        this.item = this.order.getItem(this.itemId);
    };

    this.after('initialize', function () {
        this.itemId = this.$node.data('item');
        this.on(document, "setOrderData", this.setOrder);
        this.on('click', {
            launchBtnSelector: this.handleClick,
            cancelBtnSelector: this.handleCancelClick,
        });
    });
}
