import React from 'react';
import { OffsetPresses } from "./OffsetPresses";
import { FoldingMachines } from "./FoldingMachines";
import { BindingMachines } from "./BindingMachines";
import { DigitalPresses } from "./DigitalPresses";
export var AvailableEquipment = function (_a) {
    var configuration = _a.configuration, awesomeQueryBuilder = _a.awesomeQueryBuilder, doGetConfiguration = _a.doGetConfiguration;
    return (React.createElement("div", null,
        React.createElement(OffsetPresses, { configuration: configuration, doGetConfiguration: doGetConfiguration }),
        React.createElement(DigitalPresses, { configuration: configuration, doGetConfiguration: doGetConfiguration }),
        React.createElement(FoldingMachines, { configuration: configuration, doGetConfiguration: doGetConfiguration }),
        React.createElement(BindingMachines, { configuration: configuration, awesomeQueryBuilder: awesomeQueryBuilder, doGetConfiguration: doGetConfiguration })));
};
