var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Spinner, Alert, Button, Form, Row, Col, Card } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
var CopiesMetadataEditor = function (_a) {
    var productId = _a.productId, subProductId = _a.subProductId, santaType = _a.santaType;
    var _b = useState(false), isLoading = _b[0], setLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useState(null), successMessage = _d[0], setSuccessMessage = _d[1];
    var _e = useState(null), metadataDocumentWrapper = _e[0], setMetadataDocumentWrapper = _e[1];
    var _f = useState(false), useCustom = _f[0], setUseCustom = _f[1];
    useEffect(function () {
        setLoading(true);
        axios
            .get("/api/admin/metadata/product/copies/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType))
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            if (subProductId !== 0) {
                setUseCustom(response.data.productMetadataDocument !== null);
            }
            setLoading(false);
        })
            .catch(function () {
            setLoading(false);
            setError('Failed to load copies metadata document.');
        });
    }, [productId, subProductId, santaType]);
    if (isLoading) {
        return React.createElement(Spinner, { animation: "grow", variant: "primary" });
    }
    if (!metadataDocumentWrapper) {
        return React.createElement(Alert, { variant: "danger" }, "No Copies Metadata available");
    }
    var validationSchema = Yup.object({
        data: Yup.object({
            initialValue: Yup.number()
                .min(1, 'Initial value must be greater than or equal to 1')
                .max(100000, 'Initial value cannot be greater than 100,000')
                .integer('Initial value must be a whole number')
                .required('Initial value is required'),
            stepValue: Yup.number()
                .min(1, 'Step value must be greater than or equal to 1')
                .integer('Step value must be a whole number')
                .required('Step value is required'),
            minimumValue: Yup.number()
                .min(1, 'Minimum value must be greater than or equal to 1')
                .integer('Minimum value must be a whole number')
                .required('Minimum value is required'),
        }),
    });
    var activeDocument = useCustom ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
    var handleSave = function (values, _a) {
        var setSubmitting = _a.setSubmitting;
        setLoading(true);
        setError(null);
        setSuccessMessage(null);
        // Handle case where activeDocument is null
        var newDocument = activeDocument || {
            shopId: metadataDocumentWrapper.defaultProductMetadataDocument.shopId,
            productId: metadataDocumentWrapper.defaultProductMetadataDocument.productId,
            subProductId: subProductId,
            santaType: metadataDocumentWrapper.defaultProductMetadataDocument.santaType,
        };
        var saveRequest = {
            useDefault: !useCustom,
            copiesMetadataDocument: __assign(__assign({}, newDocument), { data: values.data }),
        };
        axios
            .post("/api/admin/metadata/product/copies/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType), saveRequest)
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            setLoading(false);
            setSubmitting(false);
            setSuccessMessage('Copies Metadata saved successfully!');
        })
            .catch(function (error) {
            var _a;
            setLoading(false);
            setSubmitting(false);
            setError("Failed to save copies document: ".concat(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || error.message));
        });
    };
    return (React.createElement("div", null,
        React.createElement(Formik, { initialValues: activeDocument || { data: { initialValue: '', stepValue: '', minimumValue: '' } }, validationSchema: validationSchema, enableReinitialize: true, onSubmit: handleSave }, function (_a) {
            var isSubmitting = _a.isSubmitting;
            return (React.createElement(React.Fragment, null,
                React.createElement(Card, { className: "p-4 shadow-sm bg-light" },
                    React.createElement(FormikForm, null,
                        React.createElement("h5", { className: "mb-4" }, "Copies Metadata"),
                        successMessage && (React.createElement(Alert, { variant: "success", onClose: function () { return setSuccessMessage(null); }, dismissible: true }, successMessage)),
                        error && (React.createElement(Alert, { variant: "danger", onClose: function () { return setError(null); }, dismissible: true }, error)),
                        subProductId !== 0 && (React.createElement(Form.Group, { className: "mb-4" },
                            React.createElement(Form.Check, { type: "radio", label: "Use Default Metadata", checked: !useCustom, onChange: function () { return setUseCustom(false); } }),
                            React.createElement(Form.Check, { type: "radio", label: "Customize Metadata", checked: useCustom, onChange: function () { return setUseCustom(true); } }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 6 },
                                React.createElement(Form.Group, { controlId: "data.initialValue" },
                                    React.createElement(Form.Label, null,
                                        React.createElement("strong", null, "Initial Value")),
                                    React.createElement(Field, { type: "number", name: "data.initialValue", className: "form-control", disabled: subProductId !== 0 && !useCustom }),
                                    React.createElement(Form.Text, { className: "text-muted" }, "The starting value for the number of copies"),
                                    React.createElement(ErrorMessage, { name: "data.initialValue", component: "div", className: "text-danger small mt-1" }))),
                            React.createElement(Col, { md: 6 },
                                React.createElement(Form.Group, { controlId: "data.stepValue" },
                                    React.createElement(Form.Label, null,
                                        React.createElement("strong", null, "Step Value")),
                                    React.createElement(Field, { type: "number", name: "data.stepValue", className: "form-control", disabled: subProductId !== 0 && !useCustom }),
                                    React.createElement(Form.Text, { className: "text-muted" }, "The increment value used when increasing the number of copies"),
                                    React.createElement(ErrorMessage, { name: "data.stepValue", component: "div", className: "text-danger small mt-1" })))),
                        React.createElement(Row, { className: "mt-4" },
                            React.createElement(Col, { md: 6 },
                                React.createElement(Form.Group, { controlId: "data.minimumValue" },
                                    React.createElement(Form.Label, null,
                                        React.createElement("strong", null, "Minimum Value")),
                                    React.createElement(Field, { type: "number", name: "data.minimumValue", className: "form-control", disabled: subProductId !== 0 && !useCustom }),
                                    React.createElement(Form.Text, { className: "text-muted" }, "The minimum number of copies that can be ordered"),
                                    React.createElement(ErrorMessage, { name: "data.minimumValue", component: "div", className: "text-danger small mt-1" })))),
                        React.createElement(Button, { type: "submit", disabled: isSubmitting, variant: "primary", className: "mt-4 px-4 py-2" }, "Save Changes")))));
        })));
};
export default CopiesMetadataEditor;
